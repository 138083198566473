<template>
  <div class="allowanceDetailBody">
    <PocCardTable class="allowanceDetail">
<!--      <template v-slot:headerTitle>Allowance Repo</template>-->
<!--      <template v-slot:headerGroup>-->
<!--        <el-button @click="search" type="primary">查询</el-button>-->
<!--        <el-button @click="download" type="primary">下载</el-button>-->
<!--        <el-button @click="goback">返回</el-button>-->
<!--      </template>-->
      <template>
        <div class="flexBetw opeartionBox">
          <div class="pcth-title-left">Allowance Repo</div>
          <div>
            <el-button @click="search" type="primary">查询</el-button>
            <el-button @click="download" type="primary">下载</el-button>
            <el-button @click="goback">返回</el-button>
          </div>
        </div>
      </template>
      <template>
        <el-form
          :inline="true"
          label-position="top"
          :model="formInline"
          class="personnelRequisitionForm"
        >
          <el-form-item label="View Q">
            <el-select
              v-model="formInline.viewQ"
              clearable
              filterable
              placeholder="请选择"
            >
              <el-option
                v-for="item in viewQs"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="HFM Code">
            <el-select
              v-model="formInline.hfmCode"
              clearable
              filterable
              placeholder="请选择"
            >
              <el-option
                v-for="item in hfmCodes"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="Agency">
            <el-select
              v-model="formInline.agency"
              clearable
              filterable
              placeholder="请选择"
            >
              <el-option
                v-for="item in agencys"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="Department">
            <el-select
              v-model="formInline.departmentName"
              clearable
              filterable
              placeholder="请选择"
            >
              <el-option
                v-for="item in departments"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="Employee Number" label-position="top">
            <el-input placeholder="" v-model="formInline.employeeNumber">
            </el-input>
          </el-form-item>

          <el-form-item label="Emp Name" label-position="top">
            <el-input placeholder="" v-model="formInline.empName"> </el-input>
          </el-form-item>
        </el-form>
      </template>
    </PocCardTable>
    <PocCardTable style="margin-top: 20px">
      <PocTable
        ref="tTableRef"
        v-loading="loading"
        :data="tableData"
        :current-page.sync="pageConfig.pageNum"
        :total="pageConfig.total"
        stripe
        size="large"
        :max-height="100000000"
        @size-change="pageSizeChange"
        @page-current-change="pageChange"
        highlight-current-row
      >
        <el-table-column
          v-for="item in columnConfig"
          :key="item.key"
          :prop="item.key"
          :label="item.name"
          :fixed="item.fixed"
          :sortable="item.sortable"
          :width="item.key === 'no' ? 60 : null"
          :min-width="item.width"
          show-overflow-tooltip
        ></el-table-column>
      </PocTable>
    </PocCardTable>
  </div>
</template>

<script>
import { allowanceDetailRepoTableColumn } from '@/views/payroll/TableColumn'
import PersonnelSelectionDialog from '@/components/PersonnelSelectionDialog/PersonnelSelectionDialog'
import {
  getPayrollViewQList,
  getPayrollHfmCodeList,
  getPayrollAgencyList,
  getPayrollDepartmentList,
  getPayrollAllowAnceDetailList
} from '@/api/payroll'
import { payrollAllowanceExport } from '@/api/payrollReport'

import PocSlider from '@/components/poc-slider/poc-slider'
const { Message } = $PCommon
export default {
  name: 'actData',
  mixins: [$PCommon.TableMixin],
  components: {
    PocSlider
  },

  data() {
    return {
      loading: false,
      columnConfig: allowanceDetailRepoTableColumn,
      tableData: [],
      pageConfig: {
        total: 1,
        pageNum: 1
      },
      formInline: {
        payrollUploadRecord: '',
        periodUploadRecordId: '',

        viewQ: '',
        hfmCode: '',
        agency: '',
        departmentName: '',
        employeeNumber: '',
        empName: '',
        pageSize: 10,
        pageNum: 1
      },
      viewQs: [],
      hfmCodes: [],
      agencys: [],
      departments: [],
      targetObj: '',
      toObj: '',
      periodDialogVisible: false,
      formStatus: false,
      periodRange: ''
    }
  },
  computed: {},
  created() {
    let h =
      window.innerHeight ||
      document.documentElement.clientHeight ||
      document.body.clientHeight
    this.maxHeight = h - 330

    let uploadRecordId = this.$route.query.id

    if (uploadRecordId) {
      this.formInline.periodUploadRecordId = uploadRecordId
    }

    this.queryApi()
    this.getFormLineData()
  },
  mounted() {},
  methods: {
    async getFormLineData() {
      getPayrollViewQList().then((res) => {
        this.viewQs = res.data
      })

      getPayrollHfmCodeList().then((res) => {
        this.hfmCodes = res.data
      })

      getPayrollAgencyList().then((res) => {
        this.agencys = res.data
      })

      getPayrollDepartmentList().then((res) => {
        this.departments = res.data
      })
    },

    search() {
      getPayrollAllowAnceDetailList(this.formInline).then((res) => {
        this.tableData = res.data.list
        this.pageConfig = res.data
      })
    },

    queryApi() {
      this.search()
    },

    selectPersonal(val1, val2, val3) {
      if (!this.formStatus) {
        this.targetObj = val1
        this.toObj = val2
        this.$refs.PersonnelSelectionDialogRef.show({
          type: val3
        })
      }
    },

    pageSizeChange(val) {
      this.formInline.pageSize = val
      this.queryApi()
    },

    pageChange(val) {
      this.formInline.pageNum = val
      this.queryApi()
    },

    download() {
      payrollAllowanceExport(this.formInline)
    },

    goback() {
      this.$router.push({
        path: '/pcTracker/payroll',
        query: {
          id: 'allowanceReport',
          title: 'allowanceReport'
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.allowanceDetailBody {
  height: calc(100vh - 75px);
  overflow-x: hidden;
  .flexBetw {
    display: flex;
    justify-content: space-between;
  }
  ::v-deep .el-form .el-form-item {
    margin: 16px 16px 0 0 !important;
  }
  .allowanceDetail {
    .topTitle {
      display: flex;
      justify-content: space-between;
    }
  }
}
</style>
